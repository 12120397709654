/* .App {
  text-align: center;
} */

/* .App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

#header {
  padding: 10px 0;
  /* font-size: 20px; */
  background-color: #3d414c;
  /* text-align: center; */
  color: #fff;

}

#footer {
  padding: 10px 0;
  border-top: 1px solid #d9d9d9;
  font-size: 11px;
  font-size: 12px;
  background-color: #f1f1f1;
  text-align: center;
  color: #888;
}

.header-inner {
  width: 1280px;
  background-color: #888;  
}